import request from "@/utils/request"
//获取两个交易所资金费率图预测费率表数据
export function sendRequest() {
    return request({
        method: 'get',
        url: '/fundingRate',
        params: {

        }

    })

}
//获取资金费率历史数据
export function getFundingHistory() {
    return request({
        method: 'get',
        url: '/fundingRate/history?ename=Bybit&fsname=BTCUSDT',
      //  params: data,

    })

}
