<template>
  <div id="content" class="fundingRate">
    <div class="content_rate">
      <!-- 通过showPredicted显示预测费率 -->
      <div class="fundingrate-box allTableBox showPredicted">
        <div class="show_rate">
          显示预测费率
          <el-switch v-model="showStatus" @change="changePredictedShow">
          </el-switch>
        </div>
        <div @click="changeTableColorShow()"></div>
        <el-row :gutter="20">
          <el-col :span="12" class="col-border">
            <div
              class="text-center table-titleText"
              style="font-size: 16px; font-weight: 600"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="Bottom Center 提示文字"
                placement="bottom"
              >
                <el-button>正向合约</el-button>
              </el-tooltip>
            </div>
            <div
              id="watchdata"
              class="u-fr-list tableCompents"
              data-v-26813ac8=""
            >
              <!-- v-if="tableData.length > 0" 表格的数据是一个数组,判断表格的数据源(数组),数据源的变量名(tableData)统一, 让表格有数据之后才显示表格 -->
              <!-- v-cloak 解决屏幕闪动的问题 -->
              <el-table
                v-cloak
                v-if="tableData.length > 0"
                key="leftTable"
                :data="tableData"
                style="width: 100%; background-color: transparent"
                :header-row-style="getRowClass"
                :header-cell-style="getRowClass"
                :default-sort="{ prop: 'date', order: 'descending' }"
                v-loading="loading"
                :row-class-name="tableRowClassName"
              >
                <el-table-column property="symbol" width="80" label="货币">
                  <template slot-scope="scope">
                    <div style=" text-indent: 3px">
                      <img
                        width="16"
                        height="16"
                        :src="getImgName(scope.row)"
                        alt=""
                      />
                      <!-- 展示货币数据 -->
                      {{
                        scope.row.currency_name.length > 6
                          ? scope.row.currency_name.slice(0, 5) + "."
                          : scope.row.currency_name
                      }}
                    </div>
                    <div style="min-height: 22px" class="predictedData">
                      <!-- 展示数据如果不是btc就不受predictedData类名样式控制直接展示预测费率 -->
                      <!-- 展示的数据如果不是BTC就直接不展示预测费率 -->
                      <!-- {{ scope.row.currency_name === "BTC" ? "预测费率" : "" }} -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  sortable
                  v-for="(bourse, index) in bourseDataLeft"
                  :key="index"
                  :property="bourse"
                  :label="bourse"
                >
                  <template slot="header">
                    <img
                      class="bybt-symbol-logo mr5 va-tb"
                      data-v-26813ac8=""
                      :src="getBourseLogo('1', bourse)"
                    />
                    <span class="website">{{ bourse }}</span>
                  </template>
                  <template slot-scope="scope">
                    <div
                      :class="
                        scope.row[bourse] && scope.row[bourse].next_funding_rate
                          ? ''
                          : 'nullData'
                      "
                      style="
                        dsiplay: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 100%;
                      "
                    >
                      <span
                        :class="
                          Boolean(
                            scope.row[bourse] &&
                              +scope.row[bourse].last_datetime.includes('1989')
                          )
                            ? 'noData'
                            : ''
                        "
                        :style="{
                          color: setNumberColor(+scope.row[bourse].rate),
                        }"
                      >
                        {{
                          !scope.row[bourse].last_datetime.includes("1989")
                            ? scope.row[bourse].rate === 0.01
                              ? "0.01%"
                              : (+scope.row[bourse].rate * 100).toFixed(4) + "%"
                            : "-"
                        }}
                      </span>
                      <div
                        class="colorChangeAni predictedData"
                        :style="{
                          minHeight: '22px',
                          color: setNumberColor(
                            +scope.row[bourse].next_funding_rate
                          ),
                        }"
                      >
                        <span
                          :class="
                            Boolean(
                              +scope.row[bourse].next_datetime.includes('1989')
                            )
                              ? 'noData'
                              : ''
                          "
                        >
                          {{
                            !scope.row[bourse].next_datetime.includes("1989")
                              ? (
                                  +scope.row[bourse].next_funding_rate * 100
                                ).toFixed(4) + "%"
                              : "-"
                          }}
                        </span>
                        <!-- <span>{{ scope.row[brown].rate | getRowName  }}</span> -->
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="12" style="border-right: none">
            <div
              class="text-center table-titleText"
              style="font-size: 16px; font-weight: 600"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="Bottom Center 提示文字"
                placement="bottom"
              >
                <el-button>反向合约</el-button>
              </el-tooltip>
            </div>
            <div
              id="watchdata"
              class="u-fr-list tableCompents"
              data-v-26813ac8=""
            >
              <el-table
                v-if="tableData1.length > 0"
                key="rightTable"
                :data="tableData1"
                style="width: 100%; background-color: transparent"
                :header-row-style="getRowClass"
                :header-cell-style="getRowClass"
                :default-sort="{ prop: 'date', order: 'descending' }"
                v-loading="loading"
                :row-class-name="tableRowClassName"
              >
                <el-table-column
                  sortable
                  v-for="(bourse, index) in bourseDataRight"
                  :key="index"
                  :property="bourse"
                  :label="bourse"
                >
                  <template slot="header">
                    <img
                      class="bybt-symbol-logo mr5 va-tb"
                      data-v-26813ac8=""
                      :src="getBourseLogo('2', bourse)"
                    />
                    <span class="website">{{ bourse }}</span>
                  </template>
                  <template slot-scope="scope">
                    <div
                      :class="
                        scope.row[`${bourse}right`] &&
                        scope.row[`${bourse}right`].next_funding_rate
                          ? ''
                          : 'nullData'
                      "
                      style="
                        dsiplay: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 100%;
                      "
                    >
                      <span
                        :class="
                          Boolean(
                            scope.row[`${bourse}right`] &&
                              +scope.row[`${bourse}right`].next_funding_rate
                          )
                            ? ''
                            : 'noData'
                        "
                        :style="{
                          color: setNumberColor(
                            +scope.row[`${bourse}right`].rate
                          ),
                        }"
                      >
                        {{
                          scope.row[`${bourse}right`] &&
                          scope.row[`${bourse}right`].rate
                            ? scope.row[`${bourse}right`].rate === 0.01
                              ? "0.01%"
                              : (
                                  +scope.row[`${bourse}right`].rate * 100
                                ).toFixed(4) + "%"
                            : "-"
                        }}
                      </span>
                      <div
                        class="predictedData"
                        :style="{
                          minHeight: '22px',
                          color: setNumberColor(
                            +scope.row[`${bourse}right`].next_funding_rate
                          ),
                        }"
                      >
                        <span
                          :class="
                            Boolean(
                              +scope.row[
                                `${bourse}right`
                              ].next_datetime.includes('1989')
                            )
                              ? 'noData'
                              : ''
                          "
                        >
                          {{
                            !scope.row[`${bourse}right`].next_datetime.includes(
                              "1989"
                            )
                              ? (
                                  +scope.row[`${bourse}right`]
                                    .next_funding_rate * 100
                                ).toFixed(4) + "%"
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { sendRequest } from "@/api/FundingRate.js";
export default {
  data() {
    return {
      dataTimer: null,
      stype: "1",
      loading: false, // 加载
      isChanged: false,
      //预测费率默认状态为开
      showStatus: true,
      activeIndex: "1",
      activeIndex1: "1",
      value1: true,
      bourseDataLeft: [],
      bourseDataRight: [],

      tableData: [],
      oldTableData: [
        {
          currency_name: "BTC",
          uMarginList: [
            {
              currency_name: "BTC",
              exchange_name: "Bybit",
              id: "4883",
              import_datetime: "2021-09-24 15:59:24",
              last_datetime: "1989-06-04 00:05:00",
              last_funding_rate: "999.99999999",
              next_datetime: "2021-09-24 15:59:25",
              next_funding_rate: "0.00010000",
            },
          ],
          cMarginList: [
            {
              currency_name: "BTC",
              exchange_name: "Bybit",
              id: "4881",
              import_datetime: "2021-09-24 15:59:24",
              last_datetime: "2021-09-24 08:00:00",
              last_funding_rate: "0.00008600",
              next_datetime: "2021-09-24 15:59:24",
              next_funding_rate: "-0.00002100",
            },
          ],
        },
      ],
      newTableData: [
        {
          currency_name: "BTC",
          uMarginList: [
            {
              currency_name: "BTC",
              exchange_name: "Bybit",
              id: "4883",
              import_datetime: "2021-09-24 15:59:24",
              last_datetime: "1989-06-04 00:05:00",
              last_funding_rate: "999.99999999",
              next_datetime: "2021-09-24 15:59:25",
              next_funding_rate: "0.00030000",
            },
          ],
          cMarginList: [
            {
              currency_name: "BTC",
              exchange_name: "Bybit",
              id: "4881",
              import_datetime: "2021-09-24 15:59:24",
              last_datetime: "2021-09-24 08:00:00",
              last_funding_rate: "0.00008600",
              next_datetime: "2021-09-24 15:59:24",
              next_funding_rate: "-0.00005100",
            },
          ],
        },
      ],
      tableData1: [],
    };
  },
  // 设置定时器 每隔30秒刷新一次页面数据
  created() {
    // this.getBourseRequest();
    let that = this;
    that.getList();
    // 设置定时器
    that.dataTimer = setInterval(function () {
      that.getList();
    }, 30000);
  },
  //实例挂载到页面上
  mounted() {
    // console.log(this.$store);
  },
  //实例销毁前，（包括跳转到其他路由）
  beforeDestroy() {
    //清除定时器
    clearInterval(this.dataTimer);
  },
  //监听数据变化
  watch: {
    tableData: {
      handler(newObj, oldObj) {
        // console.log(newObj, oldObj);
      },
      deep: true,
    },
  },
  //方法
  methods: {
    //奇偶行背景色不同
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }

      return "evenRow";
    },
    changeTableColorShow() {
      this.isChanged = !this.isChanged;
      if (this.isChanged) {
        this.tableData = [...this.oldTableData];
      } else {
        this.tableData = [...this.newTableData];
      }
      this.sendRequest();
    },
    getchangeData(num) {},
    getLastFundingRate(num) {
      console.log(num);
    },
    getImgName(scope) {
      if (this.tableData.length > 0) {
        try {
          let getModule = require(`@/assets/images/pages/${scope.currency_name}.png`);
          return getModule;
        } catch (error) {
          if (error) {
            //默认图片路径
            let defalutImg = require(`@/assets/images/pages/RTA.png`);
            return defalutImg;
          }
        }
      }
    },
    //数据显示<0.01绿色，0—0.01之间深色模式白色，白天模式黑色，>0.01红色
    setNumberColor(num) {
      let a = num * 100;
      if (a > 0.01) {
        return "#ea5455";
      }

      if (a <= 0.01 && a >= 0) {
        return "#fffff";
      }

      if (a < 0) {
        return "rgba(70, 193, 149, 1)";
      }
    },
    //获取交易所logo
    getBourseLogo(type, bourse) {
      // console.log("bourse", bourse);
      if (this.tableData.length > 0 && this.tableData1.length > 0) {
        let getModule = require(`@/assets/images/pages/${bourse}.png`);
        return getModule;
      }
    },
    //通过状态来判断，关就把预测费率的那行收起来，反之就把预测费率数据显示出来
    changePredictedShow(statu) {
      // console.log(statu);
      if (!statu) {
        document
          .getElementsByClassName("allTableBox")[0]
          .classList.remove("showPredicted");
      } else {
        document
          .getElementsByClassName("allTableBox")[0]
          .classList.add("showPredicted");
      }
    },
    getRowClass() {
      return "background:rgb(40,48,70);";
    },
    //获取货币种类
    getCurrencyName(type, arr) {
      let allCurrency = arr.map((v) => v.currency_name); //获取所有货币名称
      let finalCurrency = Array.from(new Set([...allCurrency])); //去重
      return finalCurrency;
    },
    //处理表格正式数据
    getShowTableData(type, arr) {
      let tempArr = [...arr]; //把获取到的数据放入一个数组里
      //判断当
      if (type === "1") {
        let cLength = this.bourseDataLeft.length;

        tempArr.forEach((v, i) => {
          //遍历返回的uMargin
          if (tempArr[i].uMarginList) {
            tempArr[i].uMarginList.forEach((vv, ii) => {
              //赋值已有数据得交易所
              tempArr[i][tempArr[i].uMarginList[ii].exchange_name] = {
                id: tempArr[i].uMarginList[ii]
                  ? tempArr[i].uMarginList[ii].id
                  : "",
                rate: tempArr[i].uMarginList[ii]
                  ? tempArr[i].uMarginList[ii].last_funding_rate
                  : "",
                last_datetime: tempArr[i].uMarginList[ii]
                  ? tempArr[i].uMarginList[ii].last_datetime
                  : "1989",
                next_datetime: tempArr[i].uMarginList[ii]
                  ? tempArr[i].uMarginList[ii].next_datetime
                  : "1989",
                next_funding_rate: tempArr[i].uMarginList[ii]
                  ? tempArr[i].uMarginList[ii].next_funding_rate
                  : "",
              };
            });
          }
          //给没有数据得交易所填充假数据
          tempArr.forEach((v, i) => {
            this.bourseDataLeft.forEach((val, index) => {
              if (!tempArr[i][val]) {
                tempArr[i][val] = {
                  id: "",
                  rate: "",
                  last_datetime: "1989",
                  next_datetime: "1989",
                  next_funding_rate: "",
                };
              }
            });
          });
        });
      } else {
        // console.log(2, type);
        let cLength = this.bourseDataRight.length;
        tempArr.forEach((v, i) => {
          if (tempArr[i].cMarginList) {
            tempArr[i].cMarginList.forEach((vv, ii) => {
              //赋值已有数据得交易所
              tempArr[i][`${tempArr[i].cMarginList[ii].exchange_name}right`] = {
                id: tempArr[i].cMarginList[ii]
                  ? tempArr[i].cMarginList[ii].id
                  : "",
                rate: tempArr[i].cMarginList[ii]
                  ? tempArr[i].cMarginList[ii].last_funding_rate
                  : "",
                last_datetime: tempArr[i].cMarginList[ii]
                  ? tempArr[i].cMarginList[ii].last_datetime
                  : "1989",
                next_datetime: tempArr[i].cMarginList[ii]
                  ? tempArr[i].cMarginList[ii].next_datetime
                  : "1989",
                next_funding_rate: tempArr[i].cMarginList[ii]
                  ? tempArr[i].cMarginList[ii].next_funding_rate
                  : "",
              };
            });
          }
        });
        //给没有数据得交易所填充假数据
        tempArr.forEach((v, i) => {
          this.bourseDataRight.forEach((val, index) => {
            if (!tempArr[i][`${val}right`]) {
              tempArr[i][`${val}right`] = {
                id: "",
                rate: "",
                last_datetime: "1989",
                next_datetime: "1989",
                next_funding_rate: "",
              };
            }
          });
        });
      }
      // console.log(type, tempArr);
      return tempArr;
    },
    //获取两个表格的交易所
    getBourseName(arr) {
      let uMarginData = [];
      let cMarginData = [];
      arr.forEach((v, i) => {
        uMarginData.push(...v.uMarginList.map((vv) => vv.exchange_name));
        cMarginData.push(...v.cMarginList.map((vv) => vv.exchange_name));
      });

      // console.log(123456,uMarginData, cMarginData);
      const finalData = Array.from(new Set(uMarginData));
      const finalData1 = Array.from(new Set(cMarginData));
      // console.log(111111,finalData, finalData1);
      //返回一个去重后得数组
      return [finalData, finalData1];
    },

    // 请求交易所接口
    // getBourseRequest() {
    //   let request1 = this.axios.get(
    //     "https://api.rta.trade/rate_api.php?stype=1"
    //   );
    //   let request2 = this.axios.get(
    //     "https://api.rta.trade/rate_api.php?stype=2"
    //   );
    //   Promise.all([request1, request2])
    //     .then(([res1, res2]) => {
    //       // console.log(res1,res2);
    //       this.bourseDataRight = res1.data.map((v) => v.exchange_name);
    //       this.bourseDataLeft = res2.data.map((v) => v.exchange_name);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    //请求两个交易所资金费率图预测费率表数据
    getList() {
      sendRequest()
        .then((res) => {
          // console.log('resres',res)
          // this.tableData = res.data.data;
          let tempData = [...res.data.data];
          // let tempData = [...this.tableData];
          //处理交易所数据

          this.bourseDataRight = this.getBourseName(tempData)[1];
          this.bourseDataLeft = this.getBourseName(tempData)[0];
          // console.log('交易所',this.bourseDataLeft,this.bourseDataRight)

          this.$set(this, "tableData", [
            ...this.getShowTableData("2", [...tempData]),
          ]);
          this.$set(this, "tableData1", [
            ...this.getShowTableData("1", [...tempData]),
          ]);
          // console.log("123", this.tableData);
          // this.loading = false
        })
        .catch((e) => {
          console.log(e);
        });
    },

    contractData(stype, resData) {
      if (stype === 1) {
      } else {
      }
    },
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.el-button {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: #33353e;
  border-color: #283046;
}
[dir] .el-button:focus,
[dir] .el-button:hover {
  border-color: #283046;
  background: #33353e;
}
.el-button:focus,
.el-button:hover {
  color: #fff;
}
// .el-button:focus{
//   background-color: #EF9E6F;
//   border-color: #EF9E6F;
// }
@keyframes changeColor1 {
  0% {
    background-color: #fb586d;
  }
  50% {
    background-color: rgba(2, 192, 118, 0.45098);
  }
  100% {
    background-color: #fff;
  }
}
.colorChangeAni {
  /* animation: changeColor1 2s; */
}
#content {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.content_rate {
  margin: 10px auto !important;
  max-width: 100%;
}
.content_rate .bybt-box {
  /*background-color:rgb(40,48,70);*/
  padding: 16px;
}
.bybt-box .text-center {
  font-size: 16px !important;
  padding-bottom: 20px;
  padding-top: 10px;
  font-weight: 600;
  color: #efc394 !important;
}
.bybt-fr .ivu-row {
  display: flex;
  justify-content: center;
}

.bybt-fr .ivu-row .ivu-col {
  padding: 0 8px;
  font-size: 16px;
  font-weight: 600;
  border-right: 1px solid #eff2f5;
  text-align: center;
  width: 734px;
  height: 100%;
}

.mr5 {
  margin-right: 5px;
}
.va-tb {
  vertical-align: text-bottom;
}
.bybt-symbol-logo {
  width: 16px;
  height: 16px;
}
.bybt-fr-symbol-solid div img {
  border-style: none;
}
.bybt-fr-symbol-solid[data-v-26813ac8] {
  font-size: 16px;
}
.el-table {
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  /*color: #fff;*/
  cursor: pointer;
}
.el-table .cell .website {
  font-weight: 600;
  color: #efc394 !important;
}
.el-table thead {
  color: #efc394 !important;
  font-weight: 500;
}
.el-table .caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 41px;
  width: 15px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}

.el-table th.el-table__cell {
  overflow: hidden;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  // background-color: #fff;
  text-align: center;
}
.el-table .el-table__cell {
  padding: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
.el-table th.el-table__cell > .cell {
  font-size: 14px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding: 0;
  width: 100%;
}
.el-table thead {
  color: #1e2026;
  font-weight: 500;
}
.el-table .el-table__cell.is-right {
  text-align: center;
}
.el-table .el-table__cell {
  padding: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.el-table .sort-caret.descending {
  border-top-color: #c0c4cc;
  bottom: 9px;
}
.el-table .cell {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
  padding-right: 0;
}
.el-table .cell .website {
  font-weight: 600;
  color: #1e2026;
}
[dir="ltr"] .el-table .el-table__cell {
  text-align: center;
}
.el-table .el-table__cell.is-right {
  text-align: center;
  width: 93px;
  height: 40.5px;
}
.el-table,
.el-table .el-table__cell,
.el-table .el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(58, 57, 57, 0.2) !important;
}
.el-table td.el-table__cell div {
  box-sizing: border-box;
  padding: 0;
}
.el-table tr {
  height: 60px;
  // background-color: #fff;
}
.el-table tr th {
  // background-color: #fff;
}

.ivu-row [data-v-0b7ecc23] {
  display: flex;
  flex-flow: row wrap;
}
</style>
<style lang="scss">
.fundingRate {
  .noData {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .predictedData {
    display: none;
  }
  .el-table {
    font-size: 14px;
    .nullData {
      display: block;
      min-height: 23px;
      width: 90px;
    }
  }
  .fundingrate-box {
    padding: 12px;
    background: #fff;
    .table-titleText button {
      background: #fff;
      color: #283046;
      border-color: #fff;
    }
    .tableCompents {
      .el-table {
        .el-table__body-wrapper {
          .el-table__row {
            .noData {
              color: #33353e !important;
            }
          }
        }
      }
    }
    .el-table__header-wrapper {
      tr,
      th {
        background: #fff !important;
      }
    }
  }
  .el-table-row {
    border-width: 1px;
    .el-table__cell .cell {
      text-align: left !important;
      text-indent: 7px !important;
    }
    .el-table__cell {
      border-bottom: 1px solid rgba(7, 7, 7, 0.2) !important;
    }
  }
  .el-col:nth-child(2) {
    padding-left: 0 !important;
  }
  .showPredicted {
    .predictedData {
      display: block;
    }
    .nullData {
      min-height: 46px;
    }
  }
  .show_rate {
    padding-bottom: 20px;
    text-align: right;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;

    .el-switch {
      .el-switch__core {
        border-color: #ffffff !important;
        background: #ffffff !important;
        width: 28px !important;
        height: 14px;
      }
      .el-switch__core:after {
        width: 12px !important;
        margin-left: -1px;
        top: -1px;
        height: 14px;
        border-radius: 100%;
        -webkit-transition: all 0.3s;
        background-color: #efc394 !important;
      }
    }
    .is-checked {
      .el-switch__core {
        border-color: #ffffff !important;
        background: #ffffff !important;
        width: 28px !important;
        height: 14px;
      }
      .el-switch__core:after {
        width: 12px !important;
        margin-left: -13px;
        top: -1px;
        height: 14px;
        border-radius: 100%;
        -webkit-transition: all 0.3s;
        background-color: #efc394 !important;
      }
    }
  }
}
.dark-layout {
  #watchdata {
    .el-table {
      .el-table__body-wrapper {
        .el-table__body tr:hover > td {
          background-color: #efc394 !important;
          .cell {
            color: #000000 !important;
            .nullData{
              span{
                color: #000000 !important;
              }
            }
          }
        }
        .el-table__row {
          .noData {
            color: #ebeef5 !important;
          }
        }
      }
    }
  }

  .fundingrate-box {
    padding: 12px;
    background: #33353e;
    .table-titleText {
      background: #2d2f3a;
      height: 80px;
      line-height: 80px;
      button {
        background: #2d2f3a;
        color: #fff;
        border-color: #2d2f3a;
      }
    }

    .el-table__header-wrapper {
      tr,
      th {
        background: rgba(102, 102, 102, 0.1) !important;
      }
    }
  }
  .el-table,
  .el-table .el-table__cell,
  .el-table .el-table__cell.is-leaf {
    border-bottom: none !important;
  }
  .col-border {
    padding-right: 0 !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
}
</style>
<style lang="scss">
.oddRow {
  //斑马纹样式

  color: white;

  background-color: rgba(102, 102, 102, 0.1) !important;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}

.evenRow {
  background-color: rgba(255, 255, 255, 0.1) !important;

  color: white;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}
</style>